$fa-font-path: "../font";
$html-font-size: 16;
$white: #fff;
$black: #000;
$red: #be0607;
$off-white: #D7D7DA;
$off-white-70: #D7D7DAb3;
$off-black: #1E1E1E;


@font-face {
  font-family: 'Horizon';
  src: url('../font/horizon.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'HorizonOutlined';
  src: url('../font/horizon_outlined.woff') format('woff');
  font-weight: 400;

}

$primary-font: 'Horizon', sans-serif;
$primary-font-outlined: 'HorizonOutlined', sans-serif;
$secondary-font: 'aktiv-grotesk-condensed'