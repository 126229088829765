@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min";

html {
	background: $black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	width: 100%;
	overflow-x: hidden;
	position: relative;
}

a {
	color: inherit;

	@include hover-focus {
		color: $white;
	}
}

.fixed-social {
	z-index: 100;
	position: fixed;
	top: 30%;
	right: 0;
	background-color: $off-white-70;
	border: 1px solid $black;
	padding: rems(10);
	border-radius: rems(10);

	.social {
		list-style: none;
		display: flex;
		flex-direction: column;
		gap: rems(10);
		justify-content: center;
		padding: 0;
		margin: 0;


		li {
			a {
				filter: invert(1);

				img {
					transition: all .3s ease-in-out;
				}

				@include hover-focus {
					img {
						opacity: .6;
					}
				}
			}
		}
	}
}

header {
	background-image: url('../img/bg/bg-hero-3.jpg');
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
	height: 100vh;
	position: relative;

	@include tablet-down {
		height: 80vh;
		background-image: url('../img/bg/bg-hero-3-mobile.jpg');
	}

	@include phone-down {
		height: 50vh;
	}

	.header-container {
		height: 100%;
		padding-bottom: rems(100);
		display: flex;
		align-items: flex-end;
		justify-content: center;

		nav {
			display: flex;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding-top: rems(20);
			align-items: center;

			@include tablet-down {
				padding-bottom: rems(50);
				// flex-direction: column;

				&.active {
					background-color: $black;

					a {
						color: $white;
					}
				}
			}

			ul.container.nav-container {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				list-style: none;
				padding: 0;

				@include tablet-down {
					flex-direction: column;
					align-items: center;
					gap: rems(30);
				}

				li.nav-item {
					display: flex;
					align-items: center;

					a {
						color: $black;
						text-transform: uppercase;
						font-size: rems(24);
						font-weight: 700;

						@include hover-focus {
							color: $red;
						}

						@include tablet-down {
							color: $white;
						}
					}

					@include tablet-down {
						@media screen and (max-width: 1100px) {
							&:not(.active) {
								opacity: 0;
								pointer-events: none;
							}
						}
					}
				}

				#logo {
					opacity: 1;
					pointer-events: all;
					max-width: rems(230);

					@include tablet-down {
						order: -1;
						margin-bottom: rems(40);
					}
				}
			}
		}

		.content-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;

			// .opt-in {
			// 	background-color: $white;
			// 	border: 2px solid $black;
			// 	text-transform: uppercase;
			// 	text-align: center;

			// 	p {
			// 		padding: rems(12) rems(8) rems(8) rems(8);
			// 		color: $black;
			// 		margin: 0;
			// 		// line-height: 1em;
			// 		font-family: $secondary-font;
			// 	}

			// 	@include hover-focus {
			// 		background-color: $black;

			// 		p {
			// 			color: $white;
			// 		}

			// 	}
			// }
		}

		.mobile-menu-wrapper {
			position: absolute;
			top: 0;
			right: 0;
			margin: rems(20);

			@include tablet-down {
				margin: rems(20);
			}

			@include phone-down {
				margin: rems(15) rems(20);
			}

			.mobile-menu-icon {
				display: none;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				pointer-events: all;
				cursor: pointer;
				position: relative;
				z-index: 30;

				// ICON SIZE
				width: 35px;
				height: 40px;

				@include tablet-down {
					display: flex;
				}
			}

			.line {
				width: 100%;
				height: 4px;
				background-color: $black;
				margin: 4.5px;
				transition: transform 0.3s ease;
				transform-origin: center;


				&.active {
					transform: rotate(45deg) !important;
					position: absolute;
					top: 30%;
					background-color: $white;

					&.active:nth-child(2) {
						display: none;
					}

					&.active:nth-child(3) {
						transform: rotate(-45deg) !important;
					}
				}
			}
		}
	}
}

main#main {

	.owl-carousel {}

	.btn {}

	section#music {


		.original {
			background-color: $off-black;
			padding: rems(60) 0;

			.container {
				h2 {
					color: $white;
				}

				.owl-carousel {
					position: relative;
					padding: 0 rems(20);

					.owl-stage {
						display: flex;
						align-items: stretch;
					}

					.item {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						height: 100%;

						img {
							margin-bottom: rems(20);
							width: 100%;
							object-fit: cover;
						}

						p {
							font-family: $primary-font;
							font-size: rems(18);
							text-align: center;
							flex: 1;
						}

						a.btn {
							border: 1px solid $white;
							padding: rems(10) rems(28);
							display: block;
							margin-top: auto;
						}
					}

					.owl-stage-outer {}

					.owl-nav {

						button {
							color: $white;
							position: absolute;
							top: 50%;
							transform: translateY(-40%);

							&.owl-prev {
								left: rems(-5);
							}

							&.owl-next {
								right: rems(-5);
							}

							.arrow {
								i {
									font-size: rems(28);

									@include hover-focus {
										transform: scale(1.1);
									}
								}
							}
						}
					}
				}
			}
		}

		.remixes {
			background-color: $white;
			background-image: url("../img/bg/bg-music-remixes.png");
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			padding: rems(60) 0;

			.container {
				h2 {
					color: $black;
				}

				.owl-carousel {
					position: relative;
					padding: 0 rems(20);

					.owl-stage {
						display: flex;
						align-items: stretch;
					}

					// Item
					.item {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						height: 100%;

						img {
							margin-bottom: rems(20);
							width: 100%;
							object-fit: cover;
						}

						p {
							font-family: $primary-font;
							font-size: rems(18);
							text-align: center;
							flex: 1;
						}

						a.btn {
							border: 1px solid $off-black;
							color: $off-black;
							padding: rems(10) rems(28);
							display: block;
							margin-top: auto;
						}
					}

					.owl-stage-outer {}

					.owl-nav {

						button {
							color: $white;
							position: absolute;
							top: 50%;
							transform: translateY(-40%);

							&.owl-prev {
								left: rems(-5);
							}

							&.owl-next {
								right: rems(-5);
							}

							.arrow {
								i {
									font-size: rems(28);

									@include hover-focus {
										transform: scale(1.1);
									}
								}
							}
						}
					}
				}
			}
		}
	}

	#tour {
		background-image: url("../img/bg/tour-bg-og-2.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 0 0 rems(100);

		@include tablet-down {
			background-position: 75%;
		}

		.scroll-container {
			height: 36px;
			background-color: $black;
			color: $white;
			overflow: hidden;
			position: relative;
			width: 100vw;
			left: 50%;
			right: 50%;
			margin-left: -50vw;
			margin-right: -50vw;

			@include tablet-down {
				padding-top: rems(3);
			}

			@include phone-down {
				padding-top: rems(5);
			}

			.scroll-text {
				position: absolute;
				height: 100%;
				display: flex;
				align-items: center;
				white-space: nowrap;
				animation: scrollText 20s linear infinite;
				will-change: transform;

				&:nth-child(2) {
					animation: scrollText2 20s linear infinite;
				}

				.word {
					padding: rems(8);
					font-weight: 700;
					font-size: rems(20);
					display: inline-block;

					&:after {
						content: '•';
						margin-left: rems(8);
					}

					&:last-child:after {
						content: '';
					}
				}
			}
		}

		.container {
			margin: rems(100) auto 0;
			background-color: rgba(0, 0, 0, 0.6);
			padding: 0;
			width: 90%;
			border: 2px dashed $white;
			overflow-y: scroll;
			height: rems(700);

			@include tablet-down {
				height: rems(500);
			}

			#tour-dates {
				font-family: $secondary-font;
				padding: rems(30) rems(40);


				.event-group {
					display: flex;
					gap: rems(16);
					margin: rems(24) 0;

					@include phone-down {
						flex-direction: column;
						text-align: center;
					}

					.event-date {
						flex-basis: 33%;
						font-family: $primary-font;
						font-weight: 700;
						font-size: rems(20);
					}

					.event-venue-and-location {
						flex-basis: 33%;

						@include phone-down {
							justify-content: center;
						}

						.event-venue {
							font-weight: 700;
							font-family: $primary-font;
							font-size: rems(16);
						}

						.event-location {
							font-weight: 700;
							font-family: $secondary-font;
							font-size: rems(16);
						}
					}

					.event-links {
						flex-basis: 33%;
						display: flex;
						gap: rems(10);
						justify-content: flex-end;

						@include phone-down {
							justify-content: center;
						}

						a.btn {
							display: inline-block;
							background-color: $red;
							border: 1px solid $red;
							color: $white;
							padding: rems(10) rems(28);
							font-weight: 600;
							font-size: rems(14);
							max-height: rems(36);

							@include hover-focus {
								background-color: transparent;
								border: 1px solid $red;
								// color: $red;
							}
						}
					}
				}
			}
		}

		.bit-follow-btn {
			margin-top: rems(20);
			display: flex;
			justify-content: center;

			iframe {}
		}
	}

	.bg-wrapper {
		background-image: url("../img/bg/bg-video.jpg");
		background-size: cover;
		background-repeat: repeat-y;
		color: $black;
		padding: rems(50) 0 rems(40);

		p,
		a {
			font-family: $secondary-font;
		}

		#video {
			.container {
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				padding: rems(50) 0;

				h2 {
					font-size: rems(52);
					margin: 0 0 rems(30);
				}

				#video-carousel {
					padding: 0 rems(50) rems(20);

					.item {
						.embed-container {
							iframe {}
						}
					}


					// Carousel
					.owl-custom-nav-prev i {
						transition: all .2s ease-in-out;
					}

					.owl-custom-nav-prev i:hover {
						transform: scale(1.1);
					}

					.owl-custom-nav-next i {
						transition: all .2s ease-in-out;
					}

					.owl-custom-nav-next i:hover {
						transform: scale(1.1);
					}

					.owl-prev {
						color: $black;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						left: rems(15);

						i {
							font-size: rems(43);
						}
					}

					.owl-next {
						color: $black;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						right: rems(15);

						i {
							font-size: rems(43);
						}
					}

				}

				.btn {
					font-family: $secondary-font;
					font-size: rems(14);
					font-weight: rems(600);
					text-transform: uppercase;
					border: 2px solid $black;
					padding: rems(12) rems(20);
				}
			}
		}

		#about {
			padding: rems(80) 0;

			.scroll-container {
				height: 36px;
				background-color: $black;
				color: $white;
				overflow: hidden;
				position: relative;
				z-index: 10;
				width: 100vw;
				left: 50%;
				right: 50%;
				margin-left: -50vw;
				margin-right: -50vw;
				transform-origin: top right;
				transform: rotate(-3deg);

				@include tablet-down {
					padding-top: rems(3);
					transform: rotate(-3deg) translateY(rems(40));
				}

				@include phone-down {
					padding-top: rems(5);
				}

				.scroll-text {
					position: absolute;
					height: 100%;
					display: flex;
					align-items: center;
					white-space: nowrap;
					animation: scrollText 20s linear infinite;
					will-change: transform;

					&:nth-child(2) {
						animation: scrollText2 20s linear infinite;
					}

					.word {
						padding: rems(8);
						font-weight: 700;
						font-size: rems(20);
						display: inline-block;

						&:after {
							content: '•';
							margin-left: rems(8);
						}

						&:last-child:after {
							content: '';
						}
					}
				}
			}

			.container {
				// padding: rems(100);

				.content-wrapper {
					display: flex;
					gap: rems(40);
					padding: rems(70) rems(70);
					margin: 0 rems(40);
					position: relative;

					@include tablet-down {
						flex-direction: column;
						padding-right: 0;
					}

					.split {
						flex: 1;
						z-index: 3;

						img {
							aspect-ratio: 1/1;
							object-fit: cover;
							object-position: center;

						}

						h2 {
							font-weight: 700;
							font-size: rems(48);
							margin: 0 0 rems(30);


							span {
								font-weight: 300;
							}
						}

						.bio {
							text-align: justify;
							width: 100%;
							max-height: rems(342);
							overflow-y: scroll;
							padding-right: rems(20);

							@include phone-down {
								padding: 0;
							}

							p {
								font-family: $secondary-font;
								font-size: rems(18);
								line-height: 23px;
							}
						}
					}

					.wrapper-bg {
						position: absolute;
						width: 150vw;
						left: 0;
						top: 0;
						height: 100%;
						background-color: #0000001A;
						border: 2px dashed $black;
					}
				}
			}
		}

		#merch {
			margin-bottom: rems(100);

			.container {
				h2 {
					text-align: center;
					font-size: rems(44);
				}


				.items {
					display: flex;
					align-items: center;
					background-color: #f5f5f5;
					border: 2px dashed $black;
					padding: rems(24);

					.item {
						img {}
					}
				}
			}
		}

		#newsletter {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $white;
			border-top: 2px solid $black;
			border-bottom: 2px solid $black;
			padding: rems(10) rems(10) rems(30);

			.wrapper {

				h2 {
					font-weight: 700;

					span {
						font-weight: 300;
					}
				}

				.newsletter {
					.inputs-wrap {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 10px;

						input[type="email"] {
							font-family: $secondary-font;
							text-transform: uppercase;
							padding-left: rems(8);
							margin: 0;
							line-height: 1em;
							font-size: rems(16);
							border: 1px solid $black;
							width: 100%;

							&:focus {
								border-color: #007bff;
								outline: none;
							}
						}

						button[type="submit"] {
							font-family: $secondary-font;
							text-transform: uppercase;
							padding: rems(1) rems(32);
							background-color: $white;
							color: $black;
							font-size: rems(16);
							border: 1px solid $black;
							cursor: pointer;
							transition: background-color 0.3s;

							&:hover,
							&:focus {
								background-color: $black;
								color: $white;
							}
						}
					}
				}

				// This class is intended for screen readers only
				.visually-hidden {
					position: absolute;
					width: 1px;
					height: 1px;
					margin: -1px;
					padding: 0;
					overflow: hidden;
					clip: rect(0, 0, 0, 0);
					border: 0;
				}
			}

		}
	}

	#contact {
		display: flex;
		padding: rems(40) 0;
		background-color: $black;

		@media screen and (max-width: 1100px) {
			flex-direction: column;
		}

		.content {
			flex: 1;
			padding: rems(20) rems(50);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			font-family: $secondary-font;

			img {}

			ul.social {
				list-style: none;
				display: flex;
				gap: rems(8);
				margin: rems(30) 0 0;
				padding: 0;

				a {
					max-width: rems(41);
				}
			}

			h2 {}

			p {}
		}
	}
}

// Footer
footer {
	font-family: $secondary-font;
	background-color: $white;
	color: $black;
	text-align: center;
	letter-spacing: 1px;
	font-size: rems(14);
	font-weight: 700;
	padding: rems(40) 0;


	.container {
		.copyright {
			p {
				a {}
			}
		}
	}
}

.dashed-border {
	position: relative;

	&:before,
	&:after {
		content: '';
		position: absolute;
		border: 1px solid transparent; // Required to ensure the space for the border
	}

	&.dashed-border-top:before,
	&.dashed-border-bottom:before {
		top: 0;
		left: 0;
		right: 0;
		height: 20px; // Adjust as needed
		background-image: linear-gradient(to right, white 20px, transparent 20px);
		background-size: 40px 1px;
		background-repeat: repeat-x;
	}

	&.dashed-border-right:before,
	&.dashed-border-left:before {
		top: 0;
		bottom: 0;
		right: 0;
		width: 20px; // Adjust as needed
		background-image: linear-gradient(white 20px, transparent 20px);
		background-size: 1px 40px;
		background-repeat: repeat-y;
	}

	&.dashed-border-top:after,
	&.dashed-border-bottom:after {
		bottom: 0;
		left: 0;
		right: 0;
		height: 20px; // Adjust as needed
		background-image: linear-gradient(to right, white 20px, transparent 20px);
		background-size: 40px 1px;
		background-repeat: repeat-x;
	}

	&.dashed-border-right:after,
	&.dashed-border-left:after {
		top: 0;
		bottom: 0;
		left: 0;
		width: 20px; // Adjust as needed
		background-image: linear-gradient(white 20px, transparent 20px);
		background-size: 1px 40px;
		background-repeat: repeat-y;
	}
}

.scrollbar-styles {

	// For Chrome, Safari, and Opera
	&::-webkit-scrollbar {
		width: 12px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 20px;
		border: 3px solid transparent;

		&:hover {
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	// For Firefox
	scrollbar-width: thin;
	scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

	// For IE and Edge
	-ms-overflow-style: -ms-autohiding-scrollbar;
}




@keyframes scrollText {
	from {
		transform: translateX(0);
	}

	to {
		transform: translateX(-100%);
	}
}

@keyframes scrollText2 {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0);
	}
}